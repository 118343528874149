<template>
  <div>
    <v-card flat>
      <v-toolbar flat><h3>Designation</h3></v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-row class="mt-n5">
            <v-col cols="3">
              <v-text-field
                outlined
                dense
                label="Designation Name"
                style="max-width: 250px"
                v-model="desginationName"
                :rules="rules_scan_anywhere"
              />
            </v-col>
            <v-col cols="1">
              <v-btn
                x-small
                @click="add_action()"
                small
                color="primary"
                class="white--text mt-2"
              >
                Add
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-card v-if="tags.length > 0">
          <div class="pa-4">
            <v-chip-group active-class="primary--text" column>
              <v-chip
                v-for="tag in tags"
                :key="tag"
                close
                @click:close="check(tag)"
              >
                {{ tag.designation_name }}
              </v-chip>
            </v-chip-group>
          </div>
        </v-card>
        <div v-if="tags.length == 0" class="d-flex justify-center">
          <h4>No Data Available</h4>
        </div>
      </v-card-text>
    </v-card>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <div v-if="componentCheck == 1">
      <DeleteDesgination
        :desginationdeletion="desginationdeletion"
        :rowinfo="rowinfo"
        @clicked="desginationdeletion = false"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
      />
    </div>
  </div>
</template>

<script>
import { GetDesgination } from "@/mixins/GetDesgination.js";
import { API, graphqlOperation } from "aws-amplify";
import { create_delete_designations } from "@/graphql/mutations.js";

import Snackbar from "@/components/SnackBar.vue";
import DeleteDesgination from "@/components/Dialogs/DeleteDesgination.vue";
export default {
  components: {
    Snackbar,
    DeleteDesgination,
  },
  mixins: [GetDesgination],
  data() {
    return {
      desginationName: "",
      desginationdeletion: false,
      SnackBarComponent: {},
      componentCheck: 0,
      rowinfo: {},
      tags: [],
      rules_scan_anywhere: [
        (v) =>
          (typeof v === "string" && v.trim().length !== 0) ||
          "Designation cannot be empty",
        (v) =>
          /^[a-z\sA-Z]{1,100}$/.test(v) ||
          "1- 100 characters, no special characters, no numbers ",
      ],
    };
  },
  async created() {
    await this.GetDesgination();
    this.tags = this.GetDesginationitems;
    this.tags.sort((a, b) =>
        a.designation_name.localeCompare(b.designation_name)
      );
   
    // this.desginationlist();
  },
  methods: {
    check(tag) {
      this.rowinfo = tag;
      
      this.componentCheck = 1;
      this.desginationdeletion = true;
    },
    error_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },
    async success_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
      this.desginationdeletion = false;
      await this.GetDesgination();
      this.tags = this.GetDesginationitems;
    },
    add_action() {
      if (this.$refs.form.validate()) {
        if (this.desginationName) {
          
          if (!this.tags.map(s=>{return s.designation_name.toLowerCase()}).includes(this.desginationName.toLowerCase())) {
            // alert(5)
            this.create_desgination();
            // this.tags.push(this.desginationName);
            this.desginationName = ""; // Clear the input field
            this.$refs.form.resetValidation();
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Designation already exists",
            };
          }
        }
      }
    },
    async create_desgination() {
      this.loading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(create_delete_designations, {
            input: {
              user_id: this.$store.getters.get_org_user_details[0].user_id,

              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              action_type: "CREATE",
              designation_name: this.desginationName,
            },
          })
        );
        var response = JSON.parse(result.data.create_delete_designations);
        this.loading = false;
        if (response.Status == "SUCCESS") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: response.Message,
          };
          this.loading = false;
          this.desginationName = ""; // Clear the input field
          this.$refs.form.resetValidation();
          await this.GetDesgination();
          this.tags = this.GetDesginationitems;
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: response.Message,
          };
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };

        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>